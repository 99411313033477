import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { SideNav } from "../../components/Account/SideNav";
import fetchData from "../../Helpers/apiCalls";
import { useEffect } from "react";
import { useState } from "react";

export default function AccountLayout({ navItemsDefault, profile, menuUrl, children }) {
    const [navItems, setNavItems] = useState([]);

    useEffect(() => {
        const getSideNavMenu = async () => {
            const result = await fetchData(menuUrl, "GET");            
            if (result.success) {
                setNavItems(result.data);
            }
        };

        if (!!menuUrl) {
            getSideNavMenu();
        } else {
            !!navItemsDefault
                ? setNavItems(navItemsDefault)
                : setNavItems([
                      {
                          key: "personal",
                          title: "Personal",
                          items: [
                              { key: "account", title: "Account", href: "/portal/account/", icon: "UserCircle" },
                            //   { key: "security", title: "Security", href: "/portal/security/", icon: "LockKey" },
                          ],
                      },
                  ]);
        }
        return () => {};
    }, [menuUrl]);

    return (
        <Box
            sx={{
                maxWidth: "var(--Content-maxWidth)",
                m: "var(--Content-margin)",
                p: "var(--Content-padding)",
                width: "var(--Content-width)",
            }}
        >
            <Stack direction={{ xs: "column", md: "row" }} spacing={4} sx={{ position: "relative" }}>
                <SideNav navItems={navItems} profile={profile} />
                <Box sx={{ flex: "1 1 auto", minWidth: 0 }}>{children}</Box>
            </Stack>
        </Box>
    );
}
