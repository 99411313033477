import * as React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import * as P from "@phosphor-icons/react";
import { Bell as BellIcon } from "@phosphor-icons/react/dist/ssr/Bell";
import { CreditCard as CreditCardIcon } from "@phosphor-icons/react/dist/ssr/CreditCard";
import { LockKey as LockKeyIcon } from "@phosphor-icons/react/dist/ssr/LockKey";
import { PlugsConnected as PlugsConnectedIcon } from "@phosphor-icons/react/dist/ssr/PlugsConnected";
import { UserCircle as UserCircleIcon } from "@phosphor-icons/react/dist/ssr/UserCircle";
import { UsersThree as UsersThreeIcon } from "@phosphor-icons/react/dist/ssr/UsersThree";
import { getInitials } from "../../Helpers/GetInitialsFromName";

import { PencilSimple as PencilSimpleIcon } from "@phosphor-icons/react/dist/ssr/PencilSimple";
import { PaintBrushHousehold as PaintBrushHouseholdIcon } from "@phosphor-icons/react/dist/ssr/PaintBrushHousehold";
import { BookOpenText as BookOpenTextIcon } from "@phosphor-icons/react/dist/ssr/BookOpenText";
import { Wrench as WrenchIcon } from "@phosphor-icons/react/dist/ssr/Wrench";
import { Storefront as StorefrontIcon } from "@phosphor-icons/react/dist/ssr/Storefront";
import { ListChecks as ListChecksIcon } from "@phosphor-icons/react/dist/ssr/ListChecks";
import { Icon } from "@mui/material";

// more icons can be found here: https://phosphoricons.com/
const icons = {
    "credit-card": CreditCardIcon,
    "lock-key": LockKeyIcon,
    "plugs-connected": PlugsConnectedIcon,
    "user-circle": UserCircleIcon,
    "users-three": UsersThreeIcon,
    "pencil-simple": PencilSimpleIcon,
    "paint-brush-household": PaintBrushHouseholdIcon,
    bell: BellIcon,
    "book-open-text": BookOpenTextIcon,
    wrench: WrenchIcon,
    storefront: StorefrontIcon,
    "list-checks": ListChecksIcon,
};

export function SideNav({ navItems, profile }) {
    const location = useLocation();

    return (
        <div>
            <Stack
                spacing={3}
                sx={{
                    flex: "0 0 auto",
                    flexDirection: { xs: "column-reverse", md: "column" },
                    position: { md: "sticky" },
                    top: "64px",
                    width: { xs: "100%", md: "240px" },
                }}
            >
                {!!profile && (
                    <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                        <Avatar src="/assets/avatar.png">{getInitials(profile?.name)}</Avatar>
                        <div>
                            <Typography variant="subtitle1">{profile?.name}</Typography>
                            <Typography color="text.secondary" variant="caption">
                                {profile?.secondary}
                            </Typography>
                        </div>
                    </Stack>
                )}
                <Stack component="ul" spacing={3} sx={{ listStyle: "none", m: 0, p: 0 }}>
                    {navItems?.map((group, i) => (
                        <Stack component="li" key={i} spacing={2}>
                            {!!group.title ? (
                                <div>
                                    <Typography color="textSecondary" variant="caption">
                                        {group.title}
                                    </Typography>
                                </div>
                            ) : null}
                            <Stack component="ul" spacing={1} sx={{ listStyle: "none", m: 0, p: 0 }}>
                                {group?.items?.map((item, i) => (
                                    <NavItem href={item.href} pathname={location.pathname} {...item} key={i} />
                                ))}
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
            </Stack>
        </div>
    );
}

function NavItem({ disabled, external, href, icon, pathname, title }) {
    const active = isNavItemActive({ disabled, external, href, pathname });
    // const Icon = icon ? icons[icon] : null;

    const PIcon = P[icon];

    return (
        <Box component="li" sx={{ userSelect: "none" }}>
            <Box
                {...(href
                    ? {
                          component: external ? "a" : RouterLink,
                          to: href,
                          target: external ? "_blank" : undefined,
                          rel: external ? "noreferrer" : undefined,
                      }
                    : { role: "button" })}
                sx={{
                    alignItems: "center",
                    borderRadius: 1,
                    // color: "#212636",
                    color: "text.primary",
                    cursor: "pointer",
                    display: "flex",
                    flex: "0 0 auto",
                    gap: 1,
                    p: "6px 16px",
                    textDecoration: "none",
                    whiteSpace: "nowrap",
                    ...(disabled && { color: "text.disabled", cursor: "not-allowed" }),
                    ...(active && {
                        bgcolor: "rgba(0, 0, 0, 0.08)",
                        color: "text.active",
                    }),
                    "&:hover": {
                        ...(!active &&
                            !disabled && {
                                bgcolor: "rgba(0, 0, 0, 0.04)",
                                color: "text.primary",
                            }),
                    },
                }}
                tabIndex={0}
            >
                {PIcon ? (
                    <Box
                        sx={{
                            alignItems: "center",
                            color: "inherit",
                            display: "flex",
                            justifyContent: "center",
                            flex: "0 0 auto",
                        }}
                    >
                        <PIcon
                            // fill={active ? "text.active" : "text.disbled"}
                            sx={{ color: active ? "text.active" : "text.disabled" }}
                            fontSize="medium"
                            weight={active ? "fill" : undefined}
                        />
                    </Box>
                ) : (
                    <Icon sx={{ color: active ? "text.active" : "text.disabled" }} fontSize="small">
                        {icon}
                    </Icon>
                )}
                <Box sx={{ flex: "1 1 auto" }}>
                    <Typography
                        component="span"
                        sx={{ color: "inherit", fontSize: "0.875rem", fontWeight: 500, lineHeight: "28px" }}
                    >
                        {title}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

function isNavItemActive({ disabled, external, href, matcher, pathname }) {
    if (disabled || !href || external) {
        return false;
    }

    if (matcher) {
        if (matcher.type === "startsWith") {
            return pathname.startsWith(matcher.href);
        }

        if (matcher.type === "equals") {
            return pathname === matcher.href;
        }

        return false;
    }

    return pathname === href;
}
