import React, { useEffect, useState } from "react";
import {
    Alert,
    Button,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    InputLabel,
    Typography,
} from "@mui/material";
import { CustomCard, CustomContainer } from "./AccountActivation";
import CopyRight from "../../components/CopyRight";
import BootstrapInput from "../../components/BootstrapTextField";
import { fetchPublicData } from "../../Helpers/apiCalls";
import settings from "../../settings/api";

export default function RequestPasswordReset() {
    const [email, setEmail] = useState("");
    const [response, setResponse] = useState("");
    const [errors, setErrors] = useState([]);
    const [logo, setLogo] = useState("");

    useEffect(() => {
        getPortalTheme();
    }, []);

    const getPortalTheme = async () => {
        const result = await fetchPublicData(settings.api().portalInfo, "GET");
        if (result.success) {
            setLogo(result.data.theme.logoLargeUri);
        }
    };
    
    const validateEmail = async () => {
        const result = await fetchPublicData(settings.api().requestPasswordReset, "POST", { email: email });

        if (result.success) {
            setResponse(result.message);
        } else {
            setErrors(result.errors);
        }
    };

    return (
        <CustomContainer>
            <CustomCard>
                {!!logo && <img src={logo} alt="Logo" style={{ display: "block", margin: "0 auto" }} />}
                <CardHeader
                    title={
                        <Typography variant="h6" sx={{ width: "100%", textAlign: "center" }}>
                            Forgot Password?
                        </Typography>
                    }
                />
                <CardContent sx={{ width: "500px" }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Enter account email to reset password.
                    </Typography>

                    <FormControl variant="standard" fullWidth required={true}>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            <strong>Email</strong>
                        </InputLabel>
                        <BootstrapInput
                            name={"email"}
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </FormControl>
                    {errors.length > 0 &&
                        errors.map((e) => (
                            <Alert severity="error" sx={{ my: "10px" }}>
                                {e.errorMsg}
                            </Alert>
                        ))}

                    {response !== "" && (
                        <Alert severity="success" sx={{ my: "10px" }}>
                            {response}
                        </Alert>
                    )}
                </CardContent>

                <CardActions sx={{ justifyContent: "center" }}>
                    <Button onClick={() => validateEmail()} variant="contained">
                        Request Password Reset
                    </Button>
                </CardActions>
            </CustomCard>
            <CopyRight />
        </CustomContainer>
    );
}
